* {
  box-sizing: border-box;
}

html,
body,
canvas {
  width: 100%;
  max-height: 100vh;
  max-width: 100wh;
  height: 100%;
  margin: 0;
  padding: 0;
}

.dot {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transform: translate3d(-50%, -50%, 0);
  border: 2px solid white;
}
